$(function() {
    $(".order-approve").click(function (e) {
        let el = $(this)
        let orderId = parseInt(el.closest("tr").attr("data-order-id")) || null

        new Promise((resolve, reject) => {
            check_restricted_time_intervals(resolve, reject, el, { orderId })
        }).then((response)=> {
            if (response.restricted === true && confirm(response.message)) {
                approveOrder(el, { orderId })
            } else if (response.restricted === false ) {
                approveOrder(el, { orderId })
            }
        })

    })
})

let check_restricted_time_intervals = function(resolve, reject, el, data) {
    return $.ajax({
        url: `/order_management/orders/${data.orderId}/internal_restrictions`,
        method: "GET",
        dataType: "json",
        data: { code_type: data.codeType, code_value: data.codeValue },
        beforeSend: function(jqXHR, settings) {
            jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(response) {
            resolve(response)
        },
        error: function(response) {
            console.log("Error in check restricted time intervals")
        }
    })
}

let approveOrder = function(el, data) {
    $.ajax({
            url: `/order_management/orders/${data.orderId}/approvals`,
            method: "POST",
            dataType: "json",
            beforeSend: function(jqXHR, settings) {
                jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            success: function (response, request) {
                let tr_element = $(`tr[data-order-id='${data.orderId}']`)
                switch(response.status) {
                    case "ok":
                        //Action cable should remove current row if it is within order_management/orders path
                        let link = tr_element.attr("data-redirect-after-approve-link")
                        if (link) window.location = link
                        break;
                    case "error":
                        tr_element.append($("<div class='order-restricted-access'> </div>").text(response.message))
                        setTimeout(function() {
                            tr_element.children().last().remove();
                        }, 3000);
                        break;
                }
            },
            error: function (response) {
                console.log("Error in order approve request")
            }
        }
    )
}
